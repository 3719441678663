import { useState, Dispatch, useEffect } from 'react';
import clsx from 'clsx';
import { Checkbox } from '@hurtigruten/design-system-components';

import {
  PlannerDestination,
  DurationRange,
  DateRange
} from '@src/types/expeditionPlanner';
import { Icon } from '@atoms';
import { ArrowDropDownLine, ArrowDropUpLine } from '@icons/System';
import {
  addMissingDates,
  selectedDataRangeText
} from '@src/utils/departureFilter';
import { listSummary } from '@src/utils/listSummary';
import { useLocale, usePrevious, useTranslate } from '@hooks';
import { common } from '@microcopies';

import {
  Filters,
  ResetAction,
  FilterState,
  Action
} from './mobileFilterReducer';
import DateFilter from './DateFilter';
import { FILTERABLE_VOYAGE_DURATIONS } from './constants';

export const durationValuesToString = (value: DurationRange): string =>
  value.min === value.max ? `${value.min}+` : `${value.min} - ${value.max}`;

export const durationRangeOptions = FILTERABLE_VOYAGE_DURATIONS.map((v) => ({
  id: durationValuesToString(v),
  name: durationValuesToString(v),
  value: { min: v.min, max: v.max }
}));

type OptionValues = OptionValue[] | null | undefined;

type OptionValue = string | DurationRange;

type OnSelectOption =
  | ((val: string[] | null) => void)
  | ((val: DateRange | null) => void)
  | ((val: DurationRange[] | null) => void);

type OptionIsSelected = (
  selectedOptions: OptionValues,
  value: OptionValue
) => boolean;

type FilterOutSeelected = (
  selectedOptions: OptionValues,
  value: OptionValue
) => OptionValues;

const isDurationRange = (d: OptionValue): d is DurationRange =>
  Boolean(d !== 'string' && (d as DurationRange).min);

type TSelectFilter = {
  options: { id: string; name: string; value: OptionValue }[];
  onSelectOption: (val: OptionValues | null) => void;
  selectedOptions: OptionValues;
  optionIsSelected: OptionIsSelected;
  filterOutSelected: FilterOutSeelected;
  selectAllId: string;
};

type TWrapper = {
  isOpen: boolean;
  hasDivider: boolean;
  onSelectFilter: () => void;
  label: string;
  emptyText: string;
  children: any;
};

type TFilter = {
  optionIsSelected: OptionIsSelected;
  label: string;
  emptyText: string;
  isOpen: boolean;
  hasDivider: boolean;
  onSelectFilter: () => void;
  options: { id: string; name: string }[];
  onSelectOption: OnSelectOption;
  selectedOptions: OptionValues;
  filterComponent: React.ElementType;
  filterOutSelected: FilterOutSeelected;
};

type TDateFilter = {
  label: string;
  emptyText: string;
  isOpen: boolean;
  hasDivider: boolean;
  onSelectFilter: () => void;
  onSelectDateRange: (dateRange: DateRange | null) => void;
  selectedDateRange: DateRange | null;
};

type TFilterGroup = {
  whereLabel: string;
  whereEmptyText: string;
  whenLabel: string;
  whenEmptyText: string;
  durationLabel: string;
  durationEmptyText: string;
  destinations: PlannerDestination[];
  selectedDateRange: DateRange | null;
  onSelectDateRange: (val: DateRange | null) => void;
  selectedDestinations: string[] | null;
  onSelectDestinations: (val: string[] | null) => void;
  selectedDurationRange: DurationRange[] | null;
  onSelectDurationRange: (val: DurationRange[] | null) => void;
  mobileFilterState: FilterState;
  dispatch: Dispatch<Action>;
};

const SelectFilter = ({
  options,
  onSelectOption,
  selectedOptions,
  optionIsSelected,
  filterOutSelected,
  selectAllId
}: TSelectFilter) => {
  const translate = useTranslate(common, (x) => x.common);
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectOption = (value: OptionValue) => {
    let result = selectedOptions ?? [];
    if (selectAll) {
      result = options.map((x) => x.id);
      setSelectAll(false);
    }

    if (!optionIsSelected(selectedOptions, value)) {
      result = [...result, value];
    } else {
      result = filterOutSelected(result, value) || [...result];
    }
    onSelectOption(result.length === 0 ? null : result);
  };

  return (
    <div className="overflow-x-hidden overflow-y-auto max-h-[calc(100vh-450px)]">
      <Checkbox
        id={selectAllId}
        checked={selectAll}
        label={translate((x) => x.selectAll)}
        value="true"
        onClick={() => {
          setSelectAll(!selectAll);
          onSelectOption(null);
        }}
      />

      {options.map(({ id, name, value }) => (
        <div key={id} className="my-4">
          <Checkbox
            id={id}
            value={JSON.stringify(value)}
            label={name}
            name={name}
            checked={
              (selectAll || optionIsSelected(selectedOptions, value)) ?? false
            }
            onClick={() => handleSelectOption(value)}
          />
        </div>
      ))}
    </div>
  );
};

export const Wrapper = ({
  isOpen,
  hasDivider,
  onSelectFilter,
  label,
  emptyText,
  children
}: TWrapper) => (
  <div className={clsx('py-5', { 'border-t border-warm-grey': hasDivider })}>
    <div onClick={onSelectFilter}>
      <p className="mb-1 font-medium overline-text">{label}</p>
      <div className="flex flex-row items-center w-full">
        <div className="w-full">
          <p>{emptyText}</p>
        </div>
        <Icon
          aria-label={emptyText}
          icon={isOpen ? ArrowDropDownLine : ArrowDropUpLine}
        />
      </div>
    </div>
    <div
      style={
        isOpen
          ? {
              maxHeight: '300vh',
              transition: 'max-height 1.4s',
              overflow: 'hidden'
            }
          : {
              maxHeight: 0,
              overflow: 'hidden',
              transition: 'max-height 0.8s'
            }
      }
    >
      <div className="my-6 border-t border-warm-grey" />
      {children}
    </div>
  </div>
);

const Filter = ({
  filterOutSelected,
  optionIsSelected,
  emptyText,
  hasDivider,
  isOpen,
  label,
  onSelectFilter,
  options,
  selectedOptions,
  onSelectOption,
  filterComponent: FilterComponent
}: TFilter) => (
  <Wrapper
    isOpen={isOpen}
    hasDivider={hasDivider}
    onSelectFilter={onSelectFilter}
    label={label}
    emptyText={emptyText}
  >
    <FilterComponent
      {...{
        selectAllId: label,
        options,
        selectedOptions,
        onSelectOption,
        optionIsSelected,
        filterOutSelected
      }}
    />
  </Wrapper>
);

const MobileDateFilter = ({
  emptyText,
  hasDivider,
  isOpen,
  label,
  onSelectFilter,
  selectedDateRange,
  onSelectDateRange
}: TDateFilter) => (
  <Wrapper
    isOpen={isOpen}
    hasDivider={hasDivider}
    onSelectFilter={onSelectFilter}
    label={label}
    emptyText={emptyText}
  >
    <DateFilter
      filterState={selectedDateRange}
      onChange={(items) => onSelectDateRange(items)}
    />
  </Wrapper>
);

const FilterGroup = ({
  selectedDestinations,
  onSelectDestinations,
  destinations,
  selectedDurationRange,
  onSelectDurationRange,
  selectedDateRange,
  onSelectDateRange,
  whereLabel,
  whereEmptyText,
  whenLabel,
  whenEmptyText,
  durationLabel,
  durationEmptyText,
  mobileFilterState,
  dispatch
}: TFilterGroup) => {
  const locale = useLocale();
  const previewFilterState = usePrevious(mobileFilterState);
  const filtersAreClosed = Object.values(mobileFilterState).every(
    (value) => value === false
  );

  const filterOutSelectedDestinations = (
    selectedOptions: OptionValues,
    value: OptionValue
  ) => selectedOptions?.filter((i) => i !== value);

  const destinationOptionIsSelected = (
    selectedOptions: OptionValues,
    value: OptionValue
  ) => (selectedOptions ? selectedOptions.includes(value) : false);

  const filterOutSelectedDurations = (
    selectedOptions: OptionValues,
    value: OptionValue
  ): OptionValues =>
    selectedOptions &&
    selectedOptions.every(isDurationRange) &&
    isDurationRange(value)
      ? selectedOptions.filter(
          (o) => o.min !== value.min && o.max !== value.max
        )
      : selectedOptions;

  const durationOptionIsSelected = (
    selectedOptions: OptionValues,
    value: OptionValue
  ): boolean =>
    selectedOptions &&
    selectedOptions.every(isDurationRange) &&
    isDurationRange(value)
      ? selectedOptions.some((a) => a.min === value.min && a.max === value.max)
      : false;

  useEffect(() => {
    if (
      previewFilterState &&
      previewFilterState.WHEN === true &&
      mobileFilterState.WHEN === false
    ) {
      /* we just closed the date filter and need to 
      check if both dates have been selected */
      const newSelectedDateRange = addMissingDates(selectedDateRange);
      onSelectDateRange(newSelectedDateRange);
    }
  }, [mobileFilterState, selectedDateRange]);

  const selectedDestinationsArray = () => {
    const filteredDests = destinations.filter((d) =>
      selectedDestinations?.includes(d.id)
    );

    return filteredDests.map((d) => d.name);
  };

  const selectedDurationRangeArray = () =>
    selectedDurationRange
      ? selectedDurationRange.map((v) => durationValuesToString(v))
      : [];

  return (
    <div>
      <Filter
        filterOutSelected={filterOutSelectedDestinations}
        options={destinations.map((destination) => ({
          ...destination,
          value: destination.id
        }))}
        optionIsSelected={destinationOptionIsSelected}
        selectedOptions={selectedDestinations}
        onSelectOption={onSelectDestinations}
        filterComponent={SelectFilter}
        emptyText={
          selectedDestinations?.length === 0
            ? whereEmptyText
            : listSummary({
                list: selectedDestinationsArray(),
                maxCharCount: 20,
                emptyText: whereEmptyText,
                moreText: 'more'
              })
        }
        isOpen={mobileFilterState.WHERE}
        hasDivider={false}
        label={whereLabel}
        onSelectFilter={() =>
          !mobileFilterState.WHERE || filtersAreClosed
            ? dispatch({ type: Filters.WHERE })
            : dispatch({ type: ResetAction.RESET })
        }
      />
      <MobileDateFilter
        selectedDateRange={selectedDateRange}
        onSelectDateRange={onSelectDateRange}
        emptyText={
          selectedDateRange
            ? selectedDataRangeText(selectedDateRange, locale)
            : whenEmptyText
        }
        isOpen={mobileFilterState.WHEN}
        hasDivider={true}
        label={whenLabel}
        onSelectFilter={() =>
          !mobileFilterState.WHEN || filtersAreClosed
            ? dispatch({ type: Filters.WHEN })
            : dispatch({ type: ResetAction.RESET })
        }
      />
      <Filter
        filterOutSelected={filterOutSelectedDurations}
        optionIsSelected={durationOptionIsSelected}
        options={durationRangeOptions}
        selectedOptions={selectedDurationRange}
        onSelectOption={onSelectDurationRange}
        filterComponent={SelectFilter}
        emptyText={
          selectedDurationRange?.length === 0
            ? durationEmptyText
            : listSummary({
                list: selectedDurationRangeArray(),
                maxCharCount: 20,
                emptyText: durationEmptyText,
                moreText: 'more'
              })
        }
        isOpen={mobileFilterState.DURATION}
        hasDivider={true}
        label={durationLabel}
        onSelectFilter={() =>
          !mobileFilterState.DURATION || filtersAreClosed
            ? dispatch({ type: Filters.DURATION })
            : dispatch({ type: ResetAction.RESET })
        }
      />
    </div>
  );
};

export default FilterGroup;
