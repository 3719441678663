import { Block, Node, Inline, helpers } from '@contentful/rich-text-types';

const documentToPlainText = (
  rootNode: Block | Inline,
  blockDivisor: string = ' '
): string => {
  if (!rootNode || !rootNode.content) {
    return '';
  }

  return (rootNode as Block).content.reduce(
    (acc: string, node: Node, i: number): string => {
      let nodeTextValue: string = '';

      if (helpers.isText(node)) {
        nodeTextValue = node.value;
      } else if (helpers.isBlock(node) || helpers.isInline(node)) {
        nodeTextValue = documentToPlainText(node, blockDivisor);
        if (!nodeTextValue.length) {
          return acc;
        }
      }

      const nextNode = rootNode.content[i + 1];
      const isNextNodeBlock = nextNode && helpers.isBlock(nextNode);
      const divisor = isNextNodeBlock ? blockDivisor : '';
      return acc + nodeTextValue + divisor;
    },
    ''
  );
};

export default documentToPlainText;
