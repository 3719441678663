import { Reducer } from 'react';

export enum Filters {
  WHERE = 'WHERE',
  WHEN = 'WHEN',
  DURATION = 'DURATION'
}

export enum ResetAction {
  RESET = 'RESET'
}

export type Actions = Filters | ResetAction;

export type Action = {
  type: Actions;
};

export const mobileFilterReducer: Reducer<FilterState, Action> = (
  state,
  action
) => {
  const { type } = action;
  switch (type) {
    case Filters.WHERE:
      return {
        [Filters.WHERE]: true,
        [Filters.WHEN]: false,
        [Filters.DURATION]: false
      };
    case Filters.WHEN:
      return {
        [Filters.WHERE]: false,
        [Filters.WHEN]: true,
        [Filters.DURATION]: false
      };
    case Filters.DURATION:
      return {
        [Filters.WHERE]: false,
        [Filters.WHEN]: false,
        [Filters.DURATION]: true
      };
    case ResetAction.RESET:
      return {
        [Filters.WHERE]: false,
        [Filters.WHEN]: false,
        [Filters.DURATION]: false
      };
    default:
      return state;
  }
};

export type FilterState = {
  [Filters.WHEN]: boolean;
  [Filters.WHERE]: boolean;
  [Filters.DURATION]: boolean;
};

export const initialMobileFilterState: FilterState = {
  [Filters.WHEN]: false,
  [Filters.WHERE]: false,
  [Filters.DURATION]: false
};
