import { CruiseCardDataB2cExpeditionOffer } from '@content/queries/fragments/contentApi/cruiseCardParts';
import { OfferPage } from '@content/models/offerPage';

import { notEmpty } from '../typeGuards/shared';

export const getOfferTagForVoyage = (
  offer: OfferPage,
  voyageId: string,
  voyageDepartureDates: string[] = []
) => {
  // Dates are of format 2024-01-25T00:00:00,
  // where the time part is optional.
  // Taking the first 10 corresponds to 2024-01-25.
  const mappedDepartures = voyageDepartureDates.map((x) => x.slice(0, 10));
  const matchesDeparture = (dates: string[]) =>
    dates.length === 0 ||
    dates.map((d) => d.slice(0, 10)).some((d) => mappedDepartures.includes(d));

  const specificTag = offer.tags?.find(
    (t) =>
      t.applicableVoyageIds.includes(voyageId) &&
      t.applicableDates &&
      matchesDeparture(t.applicableDates)
  );
  const genericTag = offer.tags?.find(
    (t) =>
      t.applicableVoyageIds.length === 0 &&
      t.applicableDates &&
      matchesDeparture(t.applicableDates)
  );

  return specificTag?.name || genericTag?.name || null;
};

export const getPrioritizedOfferTagsForVoyage = (
  offers: Pick<CruiseCardDataB2cExpeditionOffer, 'tags'>[],
  voyageId: string
) => {
  const specificTags = offers.map(
    (offer) =>
      offer.tags.find((t) => t.applicableVoyageIds.includes(voyageId))?.name
  );

  const genericTags = offers.map(
    (offer) => offer.tags.find((t) => t.applicableVoyageIds.length === 0)?.name
  );

  return specificTags
    .map((specificTag, i) => specificTag || genericTags?.[i] || null)
    .filter(notEmpty)
    .filter((t) => t.trim() !== '');
};
