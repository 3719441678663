import { CruiseCardPartsFragment } from '@content/gql/graphql';
import { getPrioritizedOfferTagsForVoyage } from '@utils/offers/getOfferTagForVoyage';
import { documentToPlainText } from '@src/utils';

import { notEmpty } from '../../utils/typeGuards/shared';
import {
  CruiseCardData,
  CruiseCardDataB2cExpeditionOffer
} from '../queries/fragments/contentApi/cruiseCardParts';
import { CabinsWithPromotions } from './availabilityData';

export interface Availability {
  date: string;
  price: number;
  strikethroughPrice: number;
  packageCode: string;
  cabinsWithPromotions: CabinsWithPromotions[];
}

export interface CruiseCard {
  __typename?: 'CruiseCard';
  sysId: string;
  id: string;
  location: string | null;
  bookable: boolean | null;
  title: string;
  titleInEnglish: string;
  imageUrl: string | null;
  slug: string;
  bookingCodes: string[];
  destinationNames: string[];
  isPastOrCancelled: boolean;
  tags: string[];
  offerIds: string[];
  port: string | null;
  ships: { id: string; name: string }[] | null;
  availability: Availability[] | null;
  duration: number | null;
  shortDescription?: string;
  map?: {
    url: string | null;
  } | null;
}

const selectVisibleOfferTags = (
  tag: CruiseCardDataB2cExpeditionOffer,
  departureDates: string[]
) => {
  // No mapping required
  if (tag.tags.every((x) => x.applicableDates.length === 0)) {
    return tag;
  }

  // Dates are of format 2024-01-25T00:00:00,
  // where the time part is optional.
  // Taking the first 10 corresponds to 2024-01-25.
  const mappedDepartures = departureDates.map((x) => x.slice(0, 10));
  const newTags = tag.tags
    .map((t) => {
      if (t.applicableDates.length === 0) {
        return t;
      }
      const isTagContainingAtLeastOneDeparture = t.applicableDates
        .filter(notEmpty)
        .some((x) => mappedDepartures.includes(x));
      return isTagContainingAtLeastOneDeparture ? t : null;
    })
    .filter(notEmpty);
  return { ...tag, tags: newTags };
};

export const mapCruiseCardFromData = (
  data: CruiseCardData | CruiseCardPartsFragment
): CruiseCard => ({
  __typename: 'CruiseCard',
  sysId: data.id,
  id: data.id,
  location: data.destinations[0]?.name ?? null,
  bookable: true,
  title: data.name,
  titleInEnglish: data?.titleInEnglish ?? '',
  shortDescription: documentToPlainText(data.shortDescription),
  imageUrl: data.mainImage?.url ?? '',
  slug: data.slug,
  bookingCodes: data.bookingCodes,
  destinationNames: data.destinations.map((x) => x.name),
  tags: getPrioritizedOfferTagsForVoyage(
    data.availability?.b2cExpeditionOffers
      ?.filter((x) => x.showOfferTag)
      .map((x) =>
        selectVisibleOfferTags(
          x,
          data.availability?.departures.map((y) => y.date) ?? []
        )
      )
      .filter(notEmpty) ?? [],
    data.id
  ),
  port: null,
  ships: data.ships.map((s) => ({
    id: s.id,
    name: s.name
  })),
  isPastOrCancelled: data.availability?.isPastOrCancelled ?? false,
  availability: data.availability?.departures ?? null,
  duration: data.duration ?? data.availability?.duration ?? null,
  offerIds: data.availability?.b2cExpeditionOffers.map((x) => x.id) ?? [],
  map: data.map ?? null
});

export const toCruiseCardInfo = (
  card: CruiseCard
): ViewModel.CruiseCard.TCruiseCardInfo => ({
  id: card.sysId,
  location: card.location ?? null,
  title: card.title,
  titleInEnglish: card?.titleInEnglish ?? '',
  imageUrl: card.imageUrl ?? null,
  slug: card.slug,
  bookingCodes: card.bookingCodes,
  destinationNames: card.destinationNames,
  tags: card.tags ?? null,
  port: card.port ?? null,
  ships: card.ships?.map((x) => x.name),
  availability: {
    voyageId: card.sysId,
    forceRefresh: false,
    availabilityData: {
      duration: card.duration ?? null,
      voyages:
        card.availability?.map((x) => ({
          appliedPromotionCode: '',
          ...x
        })) ?? []
    },
    sysId: '',
    contentType: ''
  }
});
