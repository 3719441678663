import React from 'react';
import clsx from 'clsx';

import { TimeLine, Timer2Line } from '@icons/System';
import { Icon } from '@atoms';
import tailwindConfig from '@src/tailwind.config';

const UrgencyOfferMessage = ({
  urgencyOfferMessage,
  primaryColor = 'white',
  hasBackground = false,
  style,
  hasIcon = true
}: {
  urgencyOfferMessage: string;
  primaryColor?: 'green' | 'red' | 'white' | 'black';
  hasBackground?: boolean;
  style?: 'primary' | 'secondary';
  hasIcon?: boolean;
}) => (
  <div
    className={clsx('flex items-center gap-1 py-1 rounded-2xl', {
      'px-0': !hasBackground,
      'px-2': hasBackground,
      'text-primary-hrg-red bg-transparent': primaryColor === 'red',
      'text-black bg-light-green self-start': primaryColor === 'green',
      'text-black bg-white': primaryColor === 'white',
      'text-white bg-hx-caviar': primaryColor === 'black'
    })}
  >
    {hasIcon && (
      <Icon
        icon={style === 'primary' ? TimeLine : Timer2Line}
        fill={
          primaryColor !== 'red'
            ? tailwindConfig.theme.colors.black
            : tailwindConfig.theme.extend.colors['primary-hrg-red']
        }
      />
    )}
    <p
      className={
        primaryColor === 'white' || primaryColor === 'black'
          ? 'ui-text'
          : 'caption'
      }
    >
      {urgencyOfferMessage}
    </p>
  </div>
);

export default UrgencyOfferMessage;
