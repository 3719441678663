import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import { useIsVisible, useOnLoad } from '@hooks';

import ContentfulImage from './ContentfulImage';

const LazyVideo = ({
  title,
  priority = false,
  videoUrl,
  imageUrl,
  aspectRatio
}: {
  priority?: boolean;
  title?: string;
  videoUrl?: string;
  imageUrl?: string;
  aspectRatio?: number;
}) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isTransitioningToShowVideo, setIsTransitioningToShowVideo] =
    useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const togglePlay = () => {
    if (!videoRef.current) {
      return;
    }
    if (videoRef.current.paused) {
      void videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const onLoad = () => {
    setIsTransitioningToShowVideo(true);
    void videoRef.current?.play();
    setTimeout(() => {
      setIsVideoLoaded(true);
      setIsTransitioningToShowVideo(false);
    }, 1000);
  };

  const isVisible = useIsVisible(videoRef, 0.95);
  useEffect(() => {
    if (!isVisible) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
  }, [isVisible]);

  useOnLoad(() => {
    if (!videoRef.current) {
      return null;
    }
    videoRef.current.src = videoUrl ?? '';
    return null;
  });

  if (!videoUrl) {
    return null;
  }

  if (
    videoRef.current &&
    !isVideoLoaded &&
    !isTransitioningToShowVideo &&
    videoRef.current.readyState === 4
  ) {
    onLoad();
  }

  return (
    <button
      onClick={togglePlay}
      key={title || videoUrl}
      className={clsx('relative flex-1 h-full w-full imageContainer')}
    >
      {imageUrl && !isVideoLoaded && (
        <ContentfulImage
          src={imageUrl}
          priority={priority}
          aspectRatio={aspectRatio}
          fit={aspectRatio ? 'fill' : undefined}
          objectFit="cover"
          className={clsx('transition-opacity duration-1000', {
            'opacity-100': !isTransitioningToShowVideo,
            'opacity-0': isTransitioningToShowVideo
          })}
          layout="fill"
        />
      )}
      {videoUrl && (
        <video
          ref={videoRef}
          onCanPlayThrough={onLoad}
          className={clsx('object-cover w-full h-full')}
          muted
          loop
          playsInline
          aria-label="Press enter to play"
        />
      )}
    </button>
  );
};

export default LazyVideo;
